.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.BoxResp { 
   margin : auto;
  @media screen and (max-width: 768px) {
  
    width : 90%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    grid-gap: 30px;
  }
  @media screen and (min-width: 768px) {
  
    max-width: 800px;
    display : grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px;
  }
}

.text-editor {
  width: 100%;
  margin: 0 auto;
}

.ql-editor {
  min-height: 200px;
}

.ql-formats {
  font-weight: bold;
  font-style: italic;
  text-decoration: underline;
}

.table-container {
  overflow-x: auto;
  border: 1px solid #ccc;
  padding: 10px; /* Add padding around the table */
  margin-top: 20px; /* Add margin at the top */
}

.data-table {
  border-collapse: collapse;
  width: 100%;
}

.data-table th,
.data-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.message-cell {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-content {
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.message-content.expanded {
  white-space: normal;
  overflow: visible;
  text-overflow: inherit;
}
